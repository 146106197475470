<template>
  <div>
    <header class="h-20 flex fixed w-full z-40" style="background-color: #ffb512">
      <div class="
          w-full
          md:w-10/12
          m-auto
          flex
          items-center
          justify-between
          space-x-3
          py-2
          px-4
        ">
        <div class="
            flex flex-row
            items-center
            justify-between
            lg:justify-start
            mr-4
            md:mr-0
          ">
          <router-link :to="{
            name: 'products',
            params: { companyUrl: company.url, uuid: company.uuid }

          }">
            <h1 class="
                md:flex
                text-gray-800
                hover:text-gray-700
                font-extrabold
                text-xl
                md:text-2xl
              " style="font-family: 'Gluten', cursive">


              <span class="hidden md:block"> {{ company.name }} </span>



              <!-- <span class="hidden md:block"> Logo do seu Zé</span> -->

              <div class="flex flex-col md:hidden">
                <span class="leading-4"> putz</span>
                <span class="ml-2 leading-3">fome</span>
              </div>
            </h1>
          </router-link>
        </div>

        <div class="flex items-center">
          <span class="line-clamp-1 text-gray-800" v-if="me.email"><span class="hidden md:inline-block">Olá,</span>
            {{ me.name }}</span>
          <!--dropdown menu opções-->
          <div class="relative" id="dropMenuHeader" v-if="me.email">
            <button @click.stop.prevent="toggle()" class="
                flex flex-row
                items-center
                w-full
                px-4
                py-2
                mt-2
                text-sm text-left text-gray-300
                md:w-auto md:inline md:mt-0
                hover:text-blue-400
                focus:outline-none focus:shadow-outline
              ">
              <div class="flex items-end">
                <span><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-800" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path
                      d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                  </svg></span>
                <svg fill="currentColor" viewBox="0 0 20 20" :class="{ 'rotate-180': isOpen, 'rotate-0': !isOpen }" class="
                    text-gray-800
                    inline
                    w-4
                    h-4
                    mt-1
                    ml-1
                    transition-transform
                    duration-200
                    transform
                    md:-mt-1
                    rotate-0
                  ">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
            </button>

            <div x-transition:enter="transition ease-out duration-100"
              x-transition:enter-start="transform opacity-0 scale-95"
              x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75"
              x-transition:leave-start="transform opacity-100 scale-100"
              x-transition:leave-end="transform opacity-0 scale-95" class="
                absolute
                right-0
                mt-2
                origin-top-right
                rounded-md
                shadow-lg
                w-52
                md:w-60
                z-50
                p-1
              " v-show="isOpen" :class="{ block: isOpen }">
              <div class="py-4 bg-white rounded-md shadow px-4 space-y-5">
                <span class="line-clamp-1 text-gray-800"> {{ me.name }}</span>

                <router-link :to="{ name: 'my.orders' }" class="
                    flex
                    space-x-2
                    items-center
                    mt-2
                    text-sm text-gray-600
                    md:mt-0
                    hover:text-blue-400
                    focus:outline-none focus:shadow-outline
                  ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                  </svg>
                  <span @click="close()">Pedidos</span>
                </router-link>

                <router-link :to="{ name: 'auth.edit' }" class="
                    flex
                    space-x-2
                    items-center
                    mt-2
                    text-sm text-gray-600
                    md:mt-0
                    hover:text-blue-400
                    focus:outline-none focus:shadow-outline
                  ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span @click="close()">Editar dados</span>
                </router-link>

                <router-link :to="{ name: 'auth.edit/address' }" class="
                    flex
                    space-x-2
                    items-center
                    mt-2
                    text-sm text-gray-600
                    md:mt-0
                    hover:text-blue-400
                    focus:outline-none focus:shadow-outline
                  ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span @click="close()">Meus endereços</span>
                </router-link>

                <router-link :to="{ name: 'help' }" class="
                    flex
                    space-x-2
                    items-center
                    mt-2
                    text-sm text-gray-600
                    md:mt-0
                    hover:text-blue-400
                    focus:outline-none focus:shadow-outline
                  ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span @click="close()">Ajuda</span>
                </router-link>

                <a href="#!" @click.prevent="logoutClient" class="
                    flex
                    text-sm text-gray-600
                    space-x-2
                    items-center
                    hover:text-blue-400
                  ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                  <span @click="close()">Sair</span>
                </a>
              </div>
            </div>
          </div>

          <!--bt entrar-->
          <div v-if="!me.name">
            <router-link :to="{ name: 'login' }" class="flex mx-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-800" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
              </svg>
              <span class="text-gray-800">Entrar</span>
            </router-link>
          </div>

          <!--carrinho-->
          <div class="h-6 w-6 text-gray-800 flex relative">
            <a @click.prevent="showModalCart" class="cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" class="text-gray-800" />
              </svg>
              <div id="para1" class="
                  flex
                  justify-center
                  items-center
                  absolute
                  w-5
                  h-5
                  rounded-full
                  bg-white
                  text-gray-800
                  right-0
                  top-4
                ">
                <span class="text-xs">{{ QtyProdCart }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </header>

    <ModalCart :isVisible="ModalCart" @close-modal="ModalCart = false" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ModalCart from "../../components/ModalCart";
export default {
  components: {
    ModalCart,
  },
  data() {
    return {
      ModalCart: false,
      isOpen: false,
    };
  },

  computed: {
    ...mapState({
      productsCart: (state) => state.cart.products,
      me: (state) => state.auth.me,
      company: (state) => state.companies.companySelected,

    }),
    QtyProdCart() {
      if (this.productsCart.length > 0) {
        return this.productsCart.reduce((acc, item) => {
          return acc + item.qty;
        }, 0);
      }
      return 0;
    },
  },
  methods: {
    ...mapActions(["logout", "getCompanySelected"]),
    ...mapMutations(["CLEAR_CART", "SET_COMPANY_SELECTED"]),


    logoutClient() {
      this.logout().then((response) => {
        this.CLEAR_CART();
        this.$router.push({ name: "login" });
      });
    },

    showModalCart() {
      this.ModalCart = true;
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },

    clickOutListener(evt) {
      if (
        !this.$el.contains(evt.target) ||
        document.getElementById("dropMenuHeader")
      ) {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.clickOutListener);
  },
  watch: {
    QtyProdCart() {
      let element = document.getElementById("para1");
      element.classList.add("addedItemToCart");
      setTimeout(() => {
        element.classList.remove("addedItemToCart");
      }, 1000);
    },
  },
};
</script>

<style scoped>
.addedItemToCart {
  animation-name: spin;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  /* modo de preenchimento /forwards é fica na opcção final  */
  animation-timing-function: ease;
}

@keyframes spin {
  50% {
    transform: translateX(-7px);
  }
}
</style>