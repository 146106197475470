<template>
  <div v-if="isVisible">
    <div
      class="
        fixed
        bg-white
        top-20
        right-0
        z-40
        h-full
        shadow-lg
        pt-14
        pb-5
        px-10
        modal
        cart
        w-full
        md:w-1/3
        lg:w-2/6
      "
      style="height: calc(100vh - 80px)"
    >
      <div class="absolute right-10 top-3">
        <button @click="closeModalCart">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div
        v-if="products.length > 0"
        class="w-full h-full relative z-50 overflow-hidden"
      >
        <div class="flex flex-col h-full">
          <div
            v-if="displayMinimumOrder"
            class="flex-grow: 0 text-sm py-4 px-4 bg-gray-100 rounded"
          >
            <p>
              O pedido mínimo para essa loja é de
              <span class="text-base font-semibold">
                R$ {{ lojaInCart.minimumOrderValue | formatprice }}</span
              >
              <br />não inclusa a taxa de entrega.
            </p>
          </div>

          <div class="flex-grow: 0 space-y-2 border-b border-gray-200 py-2">
            <div>
              <p class="text-gray-500 font-light text-sm">Seu pedido em</p>
              <p
                class="
                  text-gray-700
                  font-semibold
                  text-sm
                  line-clamp-1
                  md:line-clamp-2
                "
              >
                {{ lojaInCart.name }}
              </p>
            </div>
          </div>

          <div
            class="flex-grow: 1 py-2 flex-1"
            style="max-height: 60%; overflow: auto"
          >
            <div
              v-for="(item, index) in products"
              :key="index"
              class="border-b border-gray-200"
            >
              <div class="flex justify-between py-2">
                <div class="line-clamp-2 text-sm text-gray-900 font-semibold">
                  {{ item.qty }} X {{ item.product.name }}
                </div>

                <div class="w-1/2 text-right mr-4">
                  R$ {{ item.totalPrice | formatprice }}
                </div>
              </div>
              <!--complementos -->
              <div
                v-for="(complement, index_) in item.complements"
                :key="index_"
              >
               <p class="text-gray-900 text-sm">{{ complement.name }}</p>

               <span class=" text-sm"
                  v-for="(option, indice) in complement.optionals"
                  :key="indice"
                >
                 
                  <span class="text-gray-600 text-xs">
                    {{ option.qtd }} x {{ option.name }} 
                    <span class="hidden"> R$
                    {{ option.price | formatprice }}</span>,
                  </span>
                </span>
              </div>
               <!--complementos -->

              <div class="py-2" v-if="item.comment">
                <p class="text-gray-500 font-extralight text-sm">
                  Obs: <span>{{ item.comment }}</span>
                </p>
              </div>
              <div class="font-semibold text-base">
                <button
                  class="text-red-600 text-sm mr-3"
                  @click.prevent="editItemInCart(item, index)"
                >
                  Editar
                </button>
                <button
                  class="text-gray-500 text-sm"
                  @click.prevent="removeProductCart(index)"
                >
                  Remover
                </button>
              </div>
            </div>
          </div>
          <div
            class="
              flex-grow:
              0
              space-y-2
              my-5
              border-b border-gray-200
              py-4
              text-sm
              border-t
            "
          >
            <div class="flex justify-between">
              <span class="text-gray-500">Subtotal</span
              ><span>R$ {{ subTotalCart | formatprice }}</span>
            </div>

            <div class="flex justify-between">
              <span class="text-gray-500">Taxa de entrega</span>
              <span class="text-green-500" v-if="taxaFrete === 0">Grátis</span>
              <span v-else>{{ taxaFrete | formatprice }}</span>
            </div>
            <div class="flex justify-between">
              <span class="text-gray-900 font-semibold">Total </span>
              <span>{{ totalCart | formatprice }}</span>
            </div>
          </div>
          <div
            class="
              bg-green-400
              rounded
              text-white text-center
              relative
              py-4
              px-2
              lg:px-8
            "
          >
            <router-link :to="{ name: 'checkout' }">
              <span class="text-white text-sm md:text-base"
                >Escolhe a forma de pagamento</span
              >
            </router-link>
            <div :class="{ disabled_bt: displayMinimumOrder }"></div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col justify-center items-center h-full space-y-12"
      >
        <img
          src="@/assets/images/cart_clear2.png"
          alt="carrinho vazio"
          class="w-8/12"
        />
        <div class="flex flex-col justify-center items-center">
          <p class="text-gray-900">Sua sacola está vazia</p>
          <p class="text-gray-500 text-sm">Adicione itens</p>
        </div>
      </div>
    </div>
    <div
      class="fixed inset-0 z-30 top-20 bg-opacity-50 bg-transparent modal"
    ></div>
    <!--modal add prod-->
    <ModalEditProduct
      :productItem="itemCart"
      :isVisible="modalEdit"
      :indexProductToEdit="index"
      :company="lojaInCart"
      @close-modal="modalEdit = false"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import ModalEditProduct from "./ModalEditProduct";
export default {
  components: {
    ModalEditProduct,
  },
  props: {
    isVisible: false,
  },
  data() {
    return {
      customMminimumOrder: false,

      itemCart: "",
      index: null,
      modalEdit: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.clickOutListener);
  },
  methods: {
    closeModalCart() {
      this.$emit("close-modal", false);
    },
    clickOutListener(evt) {
      if (evt.target.classList.contains("modal")) {
        this.closeModalCart();
      }
    },
    ...mapMutations({
      removeProductCart: "REMOVE_PRODUCT_CART",
    }),

    editItemInCart(item, index) {
      this.itemCart = item;
      this.index = index;
      this.modalEdit = true;
    },
  },
  computed: {
    ...mapGetters({
      subTotalCart: "subTotalCart",
      totalCart: "totalCart",
      taxaFrete: "taxaFrete",
    }),

    ...mapState({
      products: (state) => state.cart.products,
      lojaInCart: (state) => state.cart.loja,
      me: (state) => state.auth.me,
      authenticated: (state) => state.auth.authenticated,
    }),

    displayMinimumOrder() {
      if (
        this.lojaInCart.custoMminimumOrder === 1 &&
        this.lojaInCart.minimumOrderValue > this.subTotalCart
      ) {
        return (this.customMminimumOrder = true);
      }
    },
  },
};
</script>

<style scoped>
.cart {
  animation-name: cart;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
}
.disabled_bt {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  background-color: rgba(255, 255, 255, 0.507);
}

@keyframes cart {
  100% {
    transform: translateX(100%);
  }
}
</style>