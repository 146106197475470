<template>
  <div>
    <header-component />
    <div class="base__container">
      <router-view></router-view>
    </div>
    <footer-component />
  </div>
</template>

<script>
import HeaderComponent from "./_partials/Header";
import FooterComponent from "./_partials/Footer";
export default {
  components: {
    "header-component": HeaderComponent,
    "footer-component": FooterComponent,
  },
};
</script>