<template>
  <div>
    <footer class="dark:bg-gray-800 w-10/12 m-auto py-5">

      <hr class="h-px my-6 bg-gray-300 border-none dark:bg-gray-700 " />

      <div class="flex flex-col-reverse md:flex-row items-center justify-between sp">
        <p class="text-sm text-gray-700 my-3">
          © Copyright 2022. Todos direitos reservados.
        </p>
        <p class="text-sm text-gray-700">
          contato@putzfome.com.br
        </p>
      </div>
    </footer>
    <Lgpd />
  </div>
</template>

<script>
import Lgpd from "../../components/Lgpd.vue";
export default {
  components: { Lgpd },
};
</script>

<style scoped>
footer div > a {
  @apply text-sm text-gray-800;
}
@media only screen and (max-width: 375px) {
  footer div > a,
  footer div p {
    font-size: 12px;
  }
}
</style>