<template>
  <div class="lgpd " v-if="visible">
    <div
      class="
        flex flex-col
        items-center
        justify-between
        px-10
        py-4
        bg-white
        dark:bg-gray-800
        sm:flex-row
        shadow-lg
        space-x-2
      "
    >
      <p class="py-2 text-gray-500 dark:text-white sm:py-0 text-xs">
        Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua
        experiência em nossos serviços, personalizar publicidade e recomendar
        conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda
        com tal monitoramento. Para mais detalhes, acesse nossa
        <router-link
          :to="{ name: 'privacy' }"
          class="underline underline-offset-1 text-indigo-500"
          >política</router-link
        >.
      </p>

      <div class="flex -mx-2">
        <button
          @click="acceptTerm"
          class="
            
            px-5
            py-2
            leading-5
            text-white
            transition-colors
            duration-200
            transform
            bg-gray-700
            rounded
            hover:bg-gray-600
            focus:outline-none
          "
          type="button"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/bootstrap/index";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      visible: false,
      data: {
        ip: null,
        platform: null,
        userAgent: null,
      },
    };
  },
  methods: {
    ...mapActions(["aceitLgpd"]),
    acceptTerm() {
      let lgpdArea = document.querySelector(".lgpd");
      lgpdArea.remove();

      this.aceitLgpd(this.data);
    },
  },
  mounted() {
    let lsContent = localStorage.getItem("lgpd");

    if (!lsContent) {
      this.visible = true;
      
      api.get("https://api.ipify.org?format=json").then((response) => {
        (this.data.ip = response.data.ip),
          (this.data.platform = navigator.platform);
        this.data.userAgent = navigator.userAgent;
      });
    }
  },
};
</script>

<style scoped>
.lgpd {
  position: fixed;
  bottom: 25px;
  left: 0;
  z-index: 999999999;
  width: 80%;
  height: auto;
  transform: translateX(10%);
}
</style>
